<template>
  <div style="margin-top: 60px">
    <div class="banner">
      <!-- <div class="vertical-dashed"></div>
      <div class="horizontal-dashed"></div> -->
      <div class="banner-content">
        <div class="h3" style="line-height: 50px; white-space: pre-line; word-break: keep-all">
          <div class="page-subtitle">신뢰할 수 있는 개발 파트너를 찾는 현명한 방법</div>
          <div>
            전문가들의<br />실제 구현 결과물을<br />
            <span class="primary">템플릿</span>으로 확인하세요
          </div>
        </div>
        <div class="search-box">
          <div class="search-box-input">
            <input
              type="text"
              id="search-input"
              class="search-input"
              :autocomplete="'off_' + Math.random().toString(36).substr(2, 9)"
              placeholder="템플릿 제목, 사용된 기술, 파트너명으로 검색"
              v-model="inputText"
              :style="{ paddingRight: inputText !== '' ? '60px' : '36px' }"
              @keyup.enter="getSearchResult"
              @focus="removeReadonly"
              ref="searchInputMobile" />
            <img
              v-if="inputText !== ''"
              class="del-button"
              style="width: 16px; height: 16px"
              src="/static/icon/u_close.svg"
              @click="inputText = ''" />
            <img
              class="search-button"
              style="width: 20px; height: 20px"
              src="/static/icon/fi_search.svg"
              @click="getSearchResult" />
          </div>
          <div class="search-category-wrapper">
            <button
              v-for="(item, idx) in searchCategories"
              :key="`category-${idx}`"
              class="category-button main"
              @click="moveCategory(item)">
              <img class="search-category-img" :src="getImageSrc(item)" />
              <div>
                {{ item.name }}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-img">
      <img src="/static/img/home/mobile_template_img.png" />
    </div>
    <!-- 제작 순서 -->
    <div class="create-order-wrapper">
      <div class="create-order-title h6 text-center" style="line-height: 34px">
        내 서비스 맞는 템플릿으로<br /><span class="reverse-gradient">직접 제작하거나</span><br />
        <span class="reverse-gradient">개발 파트너와 제작</span>해 보세요
      </div>
      <div class="create-order-item">
        <img style="width: 60px; height: 60px; object-fit: cover" src="/static/img/home/fi_search_circle.png" />
        <div class="create-order-item-content">
          <div class="flex-align" :class="isMobile ? 'h7' : 'h6'" style="gap: 8px">
            <span class="primary">01</span>
            <span class="main">템플릿 탐색</span>
          </div>
          <div class="body4 sub2" style="white-space: pre-line; word-break: break-all">
            즉시 개설하여 바로 시작해 볼 수 있는 템플릿부터 원하는 기능을 맞춤 개발 의뢰할 수 있는 템플릿까지!
          </div>
        </div>
      </div>
      <div class="create-order-item">
        <img style="width: 60px; height: 60px; object-fit: cover" src="/static/img/home/fi_write_circle.png" />
        <div class="create-order-item-content">
          <div class="flex-align" :class="isMobile ? 'h7' : 'h6'" style="gap: 8px">
            <span class="primary">02</span>
            <span class="main">무료체험 또는 제작 의뢰하기</span>
          </div>
          <div class="body4 sub2" style="white-space: pre-line; word-break: break-all">
            플랫폼파이 템플릿은 무료체험으로 바로 개설해 볼 수 있어요. 혹은 내 서비스와 유사한 템플릿을 찾아 제작 의뢰를
            할 수 있어요.
          </div>
        </div>
      </div>
      <div class="create-order-item">
        <img style="width: 60px; height: 60px; object-fit: cover" src="/static/img/home/fi_communication_circle.png" />
        <div class="create-order-item-content">
          <div class="flex-align" :class="isMobile ? 'h7' : 'h6'" style="gap: 8px">
            <span class="primary">03</span>
            <span class="main">파트너 미팅 및 계약</span>
          </div>
          <div class="body4 sub2" style="white-space: pre-line; word-break: break-all">
            제작 의뢰 후 미팅을 통해 내용을 공유할 수 있고 신뢰할 수 있는 파트너인지 확인 후 계약을 진행할 수 있어요.
          </div>
        </div>
      </div>
      <div class="create-order-item">
        <img style="width: 60px; height: 60px; object-fit: cover" src="/static/img/home/fi_page_circle.png" />
        <div class="create-order-item-content">
          <div class="flex-align" :class="isMobile ? 'h7' : 'h6'" style="gap: 8px">
            <span class="primary">04</span>
            <span class="main">서비스 제작 및 런칭</span>
          </div>
          <div class="body4 sub2" style="white-space: pre-line; word-break: break-all">
            계약 내용을 바탕으로 서비스 제작을 진행하고 원하던 서비스를 런칭할 수 있어요.
          </div>
        </div>
      </div>
    </div>
    <div class="content-container">
      <div id="scrollTarget"></div>
      <!-- categories 로 모바일용 좌우로 드레그 가능하게  -->
      <TemplateFilterList
        :categories="categories"
        :tempProducts="tempProducts"
        :searchCategory="searchCategory"
        :currentImages="currentImages"
        @selectCategory="selectCategory"
        @openProductFilterModal="openProductFilterModal"
        @clickFreeSetFilter="clickFreeSetFilter"
        @resetFilter="resetFilter"
        @removeFiltered="removefilterd" />
      <div v-if="tempProducts.length > 0" style="margin-top: 20px">
        <button
          class="button is-main body2-bold"
          style="width: 240px; height: 52px"
          @click="moveCategory(searchCategory)">
          템플릿 전체보기
        </button>
      </div>
    </div>
    <!-- <div class="flex-center" style="padding: 0 16px; margin-bottom: 120px">
      <div class="survey-inquiry-wrapper" style="width: 100%; gap: 24px">
        <div class="item-survey">
          <div>
            <img src="/static/icon/emoticon_hmm.png" style="width: 40px; height: 40px; margin-bottom: 20px" />
          </div>
          <div class="h6 margin-bottom-20" style="word-break: keep-all">
            내 서비스에 맞는 템플릿이 있는지 찾기 어려우신가요?
          </div>
          <a href="https://walla.my/survey/gZBAG9imMdaiyop6z6It" target="_blank">
            <div class="button-black unselect">템플릿 찾기 도움 요청</div>
          </a>
        </div>
        <div class="item-inquiry">
          <div>
            <img src="/static/icon/ic_inquiry.png" style="width: 40px; height: 40px; margin-bottom: 20px" />
          </div>
          <div class="h6 margin-bottom-20">찾는 테마가 없으시다면?<br />맞춤 플랫폼 제작!</div>
          <a href="https://launchpack.co.kr/prime" target="_blank">
            <div class="button-sub2 unselect">견적문의하기</div>
          </a>
        </div>
      </div>
    </div> -->
    <!-- 추천 파트너 -->
    <div class="partner-container">
      <div class="partner-wrapper">
        <div class="partner-title text-center">
          <div class="body2 main" style="line-height: 24px; word-break: keep-all">
            특별히 생각하는 기능이 있다면 맞춤 개발 의뢰를 해보세요.
          </div>
          <div class="h5 main margin-top-12" style="word-break: keep-all">
            플랫폼 제작 경험이 많은 개발사들이 함께 하고 있습니다.
          </div>
        </div>
        <block-banner :bannerList="popularPartner"></block-banner>
      </div>
    </div>
    <product-filter-modal ref="productFilterModal" @apply="applyFilter"></product-filter-modal>
  </div>
</template>

<script>
import BlockBanner from '../component/BlockBanner';
import ProductFilterModal from '../modal/ProductFilterModal';
import TemplateFilterList from '../component/TemplateFilterList.vue';

export default {
  name: 'TemplateHomeMobile',
  components: {
    TemplateFilterList,
    BlockBanner,
    ProductFilterModal,
  },
  created() {
    this.getData();
    this.getTemplateCategory();
    this.getPopularPartner();
    // this.searchCategories.forEach((item) => {
    //   this.$set(this.currentImages, item.id, item.img_before);
    // });
  },
  mounted() {
    // 검색창에 자동완성 기능 제거
    this.$refs.searchInputMobile.setAttribute('readonly', 'readonly');
    this.disableAutocomplete();
  },
  activated() {},
  deactivated() {},
  data() {
    return {
      inputText: '',
      searchCategory: null,
      searchMenus: [
        { name: '오픈마켓' },
        { name: '인력∙용역 마켓' },
        { name: '서비스 예약' },
        { name: '숙박/공간 예약' },
        { name: '배달' },
        { name: '디지털콘텐츠 마켓' },
        { name: 'SNS/커뮤니티' },
        { name: '위치기반 서비스' },
      ],
      searchCategories: [
        {
          created_time: '2024-08-05T15:04:28.338462',
          desc: null,
          id: 2,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27113462.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27113462.png',
          index: 0,
          is_removed: false,
          name: '오픈마켓',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.385489',
          desc: null,
          id: 4,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27765327.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27765327.png',
          index: 2,
          is_removed: false,
          name: '인력∙용역 마켓',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.405700',
          desc: null,
          id: 5,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27114647.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27114647.png',
          index: 3,
          is_removed: false,
          name: '서비스 예약',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.535878',
          desc: null,
          id: 7,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27564273.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27564273.png',
          index: 5,
          is_removed: false,
          name: '숙박/공간 예약',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.558442',
          desc: null,
          id: 8,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27107304.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27107304.png',
          index: 6,
          is_removed: false,
          name: '배달',
          parent_id: 1,
        },
        {
          created_time: '2024-08-05T15:04:28.360170',
          desc: null,
          id: 3,
          img_after:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27674949.png',
          img_before:
            'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/pickup/241108184727/27674949.png',
          index: 1,
          is_removed: false,
          name: '디지털콘텐츠 마켓',
          parent_id: 1,
        },
      ],
      categories: [],
      currentImages: {},
      selectedCategory: null,
      freeFilterBtn: false,
      tempProducts: [],
      popularPartner: [],
    };
  },
  watch: {},
  methods: {
    openProductFilterModal() {
      this.$refs.productFilterModal.open();
    },
    // 검색창에 자동완성 기능 제거
    disableAutocomplete() {
      const inputs = document.getElementsByTagName('input');
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].autocomplete = 'new-password';
      }
    },
    // 검색창에 자동완성 기능 제거
    removeReadonly() {
      this.$refs.searchInputMobile.removeAttribute('readonly');
    },
    moveCategory(category) {
      if (category === null) {
        let all = {
          id: 0,
          name: '전체',
          img_before: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770574.png',
          img_after: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770186.png',
        };
        this.$store.commit('setTemplateCategory', all);
      } else {
        this.$store.commit('setTemplateCategory', category);
      }
      this.$nextTick(() => {
        this.$router.push('/template_category');
      });
    },
    getSearchResult() {
      if (this.inputText.trim() === '') {
        return; // 검색어가 비어있으면 요청하지 않음
      }
      this.$store.commit('setSearch', this.inputText);
      this.inputText = '';
      this.$nextTick(() => {
        this.routerPush('/template_search');
      });
    },
    applyFilter(params) {
      let result = {
        page_num: 1,
        page_length: 6,
        category: 58,
        ordering: 'priority,-id',
        fields:
          'id,name,simple_desc,price,liked_count,visit,img,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs,is_liked',
      };
      if (params && params.length > 0) {
        params.forEach((param) => {
          if (param.start) {
            result[`${param.key}_start`] = param.start;
            result[`${param.key}_end`] = param.end;
          } else {
            result[param.key] = Array.isArray(param.value) ? param.value.join() : param.value;
          }
        });
      } else {
        if (result.price_start) {
          delete result[`price_start`];
          delete result[`price_end`];
        }
        result.category = '59,60,61';
      }

      let user_id = this.$store.state.user.user_id;
      this.$store.commit('setLoading', true);
      this.$axios.get(`user/${user_id}/mapping/product`, { params: result }).then((res) => {
        this.tempProducts = res.data.data;
        this.$store.commit('setLoading', false);
      });
    },
    resetFilter(item) {
      this.applyFilter(item);
    },
    removefilterd(item) {
      this.applyFilter(item);
    },
    clickFreeSetFilter() {
      this.freeFilterBtn = !this.freeFilterBtn;
      let params = {
        category: '59,60',
        page_num: 1,
        page_length: 6,
        ordering: 'priority,-id',
        fields:
          'id,name,simple_desc,price,liked_count,visit,img,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs,is_liked',
      };
      if (this.freeFilterBtn) {
        params.category = '59,60';
      } else {
        params.category = 58;
      }
      let user_id = this.$store.state.user.user_id;
      this.$store.commit('setLoading', true);
      this.$axios.get(`user/${user_id}/mapping/product`, { params: params }).then((res) => {
        this.tempProducts = res.data.data;
        this.$store.commit('setLoading', false);
      });
    },
    getPopularPartner() {
      this.$axios.get('public/partner/popular').then((res) => {
        this.popularPartner = res.data;
      });
    },
    getTemplateCategory() {
      this.$axios.get('public/template_category').then((categories) => {
        this.categories = categories.data;
        this.categories.unshift({
          id: 0,
          name: '추천',
          img_before: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770574.png',
          img_after: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770186.png',
        });
        // this.searchCategories = categories.data.filter((category) =>
        //   this.searchMenus.some((menu) => menu.name === category.name)
        // );
      });
    },
    async getData() {
      let params = {
        category: '59,60,61',
        page_num: 1,
        page_length: 6,
        ordering: '-inquiry_count,category_priority,-id',
        fields:
          'id,name,simple_desc,price,liked_count,visit,img,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs,is_liked',
      };
      this.searchCategory = {
        id: 0,
        name: '추천',
        img_before: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770574.png',
        img_after: 'https://launchpack-storage.s3.ap-northeast-2.amazonaws.com/media/img/non-path/46770186.png',
      };
      let user_id = this.$store.state.user.user_id;
      let res = await this.$axios.get(`user/${user_id}/mapping/product`, { params: params });
      this.tempProducts = res.data.data;
    },
    getImageSrc(item) {
      return this.currentImages[item.id] || item.img_before;
    },
    selectCategory(category) {
      this.searchCategory = category;
      let params = {
        template_categories: category ? category.id : null,
        page_num: 1,
        page_length: 6,
        ordering: 'priority,-id',
        fields:
          'id,name,simple_desc,price,liked_count,visit,img,partner.img,partner.name,partner.nickname,partner.career,category1,category2,category3,link,preview_url,visit,rate,reviews_count,params,theme_version,tags,techs,is_liked',
      };
      if (category === null) {
        delete params.template_categories;
        params.category = '59,60,61';
        params.ordering = 'priority,-id';
      } else if (category.id === 0) {
        delete params.template_categories;
        params.category = '59,60,61';
        params.ordering = '-inquiry_count,category_priority,-id';
      }
      let user_id = this.$store.state.user.user_id;
      this.$store.commit('setLoading', true);
      this.$axios.get(`user/${user_id}/mapping/product`, { params: params }).then((res) => {
        this.tempProducts = res.data.data;
        this.$store.commit('setLoading', false);
        this.$nextTick(() => {
          setTimeout(() => {
            const element = document.getElementById('scrollTarget');
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 100); // 100ms 지연
        });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.button
  font-size 14px
  font-weight 500

.banner
  display flex
  flex-direction column
  align-items center
  padding 60px 16px 0
  background white
  text-align center
  position relative

  // &::before
  //   content ''
  //   position absolute
  //   top 16px
  //   left 0
  //   right 0
  //   border-top 1px dashed red

  // &::after
  //   content ''
  //   position absolute
  //   top 0
  //   bottom 0
  //   left 16px
  //   border-left 1px solid red

  // .vertical-dashed
  //   content ''
  //   position absolute
  //   top 0
  //   bottom 0
  //   right 16px
  //   border-right 1px dashed #f00

  // .horizontal-dashed
  //   content ''
  //   position absolute
  //   bottom 16px
  //   left 0
  //   right 0
  //   border-bottom 1px dashed red

.banner-img
  width 100%
  margin 60px 0
  overflow hidden

  img
    width 100%
    height 100%
    object-fit cover

.page-subtitle
  margin-bottom 8px
  font-size 16px
  font-weight 400
  line-height 24px

.banner-content
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 32px

.search-box
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 32px

.search-box-input
  position: relative
  display: flex
  align-items: center
  border-radius 8px
  width 100%

.search-input
  border none
  outline none
  flex 1
  font-size 16px
  border 1px solid #f60 !important
  padding 12px 36px 12px 16px
  width 100%

.search-input::placeholder
  color #999
  font-size 15px
  white-space nowrap
  overflow hidden
  text-overflow ellipsis
  pointer-events none
  max-width 100%

.del-button
  position absolute
  top 16px
  right 44px
  cursor pointer


.search-button
  position absolute
  top 14px
  right 16px
  background none
  border none
  cursor pointer

.search-category-wrapper
  display flex
  flex-wrap wrap
  gap 8px
  justify-content center

.category-button
  display flex
  align-items center
  gap 4px
  background #fff
  border 1px solid #ddd
  border-radius 8px
  padding 6px 12px 6px 6px
  font-size 14px
  cursor pointer
  transition background-color 0.3s

button:focus
  border 1px solid #ddd

.search-category-img
  width 18px
  height 18px
  object-fit cover



.banner-white-box
  margin-top -20px
  background-color white
  border-top-left-radius 20px
  border-top-right-radius 20px
  padding 28px 20px 12px 20px
  font-size 14px
  line-height 22px
  text-align center

.gradient-primary
  background linear-gradient(90deg, #F60 46.92%, #D50000 60.89%)
  background-clip text
  -webkit-background-clip text
  -webkit-text-fill-color transparent

.reverse-gradient
  background linear-gradient(90deg, #D50000 59.5%, #F60 100%)
  background-clip text
  -webkit-background-clip text
  -webkit-text-fill-color transparent

.content-container
  display flex
  flex-direction column
  align-items center
  justify-content center
  padding 0 0 60px
  gap 20px

.create-order-wrapper
  display grid
  grid-template-columns 1fr
  align-items start
  width 100%
  gap 24px
  padding 60px 16px
  background #FAFAFA

.create-order-item
  display flex
  justify-content center
  gap 12px
.create-order-item-content
  display flex
  flex-direction column
  gap 12px

.category-slider
  width 100%
  overflow-x scroll
  overflow-y hidden
  border-bottom 1px solid #EEE
  -webkit-overflow-scrolling touch
  scroll-snap-type x mandatory

  &::-webkit-scrollbar
    display none

  scrollbar-width none

.category-slider-wrapper
  display flex
  align-items center
  gap 8px
  height 84px

.category-slider-item
  display flex
  flex-direction column
  align-items center
  gap 4px
  flex 0 0 auto
  width fit-content
  padding 8px
  min-width 80px
  height 54px
  text-align center
  scroll-snap-align start
  cursor pointer

  &.selected
    padding 8px 8px 5px
    border-bottom 3px solid #242428

  img
    width 16px
    height 16px
    object-fit cover

  div
    font-size 12px
    white-space nowrap
    overflow hidden
    text-overflow ellipsis

.platformfy-info-container
  background linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #EFEFEF 100%)
  padding-bottom 50px

.section-menu
  padding 40px 0 24px 0
  box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.05)

.grid-menu
  display grid
  grid-template-columns repeat(3, 1fr)
  grid-row-gap 28px
.item-menu
  text-align center

.section
  padding 50px 16px

.box-error-50
  margin-bottom 8px
  display flex
  justify-content center
  align-items center
  background-color $error50
  color $error
  font-size 14px
  padding 10px
  border-radius 50px
  width 100%
  img
    margin-right 12px
  b
    margin-left 4px

.box-primary-light2
  background-color $primary-light2
  color $primary
  padding 12px
  border-radius 8px

  .emoticon
    width 36px
    height 36px

#container-2
  border-top 1px solid $gray1
#container-3
  background-color $main
  position relative
  text-align center
  overflow hidden
.img-prime-back
  position absolute
  top -65px
  left calc(50% - 394px)
  background-image url(/static/img/home/img_prime_back2.png)
  width 740px
  background-size contain
  height 509px
  background-position center
  background-repeat no-repeat
  z-index 0

.slick-img
  height 168px
  width 100%
  background-repeat no-repeat
  background-position center
  background-size contain
.indicators
  display flex
  align-content center
  justify-content center
  gap 12px
  .indicator
    width 6px
    height 6px
    border-radius 100%
    background-color rgba(255, 255, 255, 0.5)
  .indicator-current
    background-color #ffffff


#container-4
  background-color $gray3

.box-app-market-desc
  background-color white
  padding 24px 20px
  border-radius 8px
  word-break keep-all

.menu4-items
  margin-top 28px
  display grid
  grid-template-columns repeat(2, 1fr)
  grid-gap 12px

.menu6
  background-color $subcolor50
  padding 28px 16px
  text-align center

a.anchor
  top -59px
  position absolute

.fixed-menu
  width 100%
  overflow hidden
  position fixed
  top 60px
  left 0
  z-index 9
  background-color white
  border-bottom 1px solid $gray1
.item-fixed-menu
  padding 12px 10px
  border-bottom 3px solid transparent
  scroll-snap-align start
  color $sub
.item-fixed-menu:first-child
  margin-left 16px
.item-fixed-menu:last-child
  margin-right 16px
.item-fixed-menu.selected
  color $primary
  font-weight 500
  border-bottom 3px solid $primary

.drag-scroll-mobile
  white-space nowrap
  overflow-x scroll
  overflow-y hidden
  scroll-snap-type x mandatory

.drag-scroll-mobile::-webkit-scrollbar
  display none

.proudct-filter-wrapper
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%
  gap 24px
  padding 0 16px

.product-filter-item
  display flex
  align-items center
  gap 8px
  width 100%

.product-list-wrapper
  display grid
  grid-template-columns repeat(1, 1fr)
  gap 24px
  width 100%

.survey-inquiry-wrapper
  display grid
  grid-template-columns repeat(1, 1fr)
  gap 24px
  width 100%
.item-survey
.item-inquiry
  border-radius 8px
  flex 1
  height 100%
  display flex
  flex-direction column
  align-items center
  justify-content center
  text-align center
  padding 32px

.item-survey
  background-color $gray3
.item-inquiry
  background-color $subcolor50

.button-black
.button-sub2
  padding 10px 24px
  border-radius 50px
  font-size 15px
  font-weight 700
  color white

.button-black
  background-color #000
.button-sub2
  background-color $subcolor2

.partner-container
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%
  padding 60px 0
  background-color #F5F5F5
.partner-wrapper
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%
  gap 24px


.filter-count
  display flex
  align-items center
  justify-content center
  height 100%
  padding 2px 5px
  border-radius 10px
  border 1px solid #fff
  background #242428
  color #fff
  font-size 10px
  font-weight 600
</style>
