<template>
  <div class="block-container">
    <slick ref="slick" :options="slickOptions" v-if="bannerList.length">
      <div
        class="partner-item"
        v-for="partner in bannerList"
        :key="`partner-${partner.id}`"
        @click="routerPush(`/partner_detail?id=${partner.id}`)"
      >
        <div class="flex-center" style="flex-direction: column; gap:2px">
          <div class="h8 main">{{ partner.nickname }}</div>
          <div class="flex-align" style="gap:2px;">
            <div class="body6 sub2">경력</div>
            <div class="body6-medium main">{{ partner.career }}</div>
          </div>
        </div>
        <div class="flex-center" style="gap:12px">
          <div class="flex-align" style="gap:4px; margin-top: 8px">
            <img src="/static/icon/u_star-primary.svg" style="width:14px; height:14px" />
            <div class="body6-bold main">
              {{ partner.rate
              }}<span class="body6 sub3"> ({{ currencyNum(partner.reviews_count ? partner.reviews_count : 0) }})</span>
            </div>
          </div>
        </div>
        <div class="tags">
          <div class="tag" v-for="(tag, index) in slicedTags(partner.techs)" :key="`tag-${index}`">
            {{ tag }}
          </div>
        </div>
      </div>
    </slick>
  </div>
</template>
<script>
export default {
  name: 'BlockBanner',
  components: {},
  mixins: [],
  props: {
    bannerList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentSlide: 0,
      slickOptions: {
        dots: false,
        arrows: false,
        autoplay: false,
        infinite: true,
        refresh: true,
        centerMode: true,
        slidesToShow: 3,
        autoplaySpeed: 5000,
        centerPadding: '',
        variableWidth: true,
        slidesToScroll: 1,
      },
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.slick) {
        this.$refs.slick.reSlick();
      }
    });
  },

  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    handleAfterChange(ev, slick, current) {
      this.currentSlide = current % this.bannerList.length;
    },
    slicedTags(tags) {
      if (tags.length <= 3) {
        return tags;
      }
      return tags.slice(0, 3);
    },
  },
  computed: {},
  watch: {
    bannerList: {
      handler() {
        this.$nextTick(() => {
          if (this.$refs.slick) {
            this.$refs.slick.reSlick();
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="stylus" scoped>


.block-container
  width 100vw
  .slick-track
    display flex
    align-items center
.title
  text-align center
  display grid
  row-gap 24px
  margin-bottom 60px

.card-wrapper
  align-items center
  height 234px
  display flex !important
  opacity 0.5
.card
  border 1px solid $border-sub
  align-items center
  width 311px
  height 207px
  border-radius 8px
img
  width 100%
  height 100%
  border-radius 8px
  padding 0 !important
  object-fit cover

.partner-item
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 8px
  width 270px !important
  // height 166px !important
  max-height 196px !important
  margin 0 4px !important
  background-color #fff
  border-radius 12px
  padding 32px

.tags
  margin-top 8px
  display flex
  justify-content center
  gap 6px
  flex-wrap wrap

.tag
  border 1px solid #e0e0e0
  border-radius 50px
  padding 2px 8px
  color #808080
  font-size 12px
</style>
<style lang="stylus">
.block-container
  .slick-center
    padding 0 8px
    .card-wrapper
      opacity 1
    .content-wrapper
      height 232px
    .author
      font-size 14px
    .card-title
      font-size 20px
      font-weight 500
      white-space pre-line
      word-break break-all
    .content
      font-size 16px
      white-space pre-line
      word-break break-all

    .card
      width 311px
      height 207px
      border-radius 8px
</style>
